import React from "react";

/* Main page */
function Main() {
  return (
    <div>
      <img
        alt="ISLAND FORMATION IN PROGRESS"
        src="/banner.png"
        className="banner"
      />
    </div>
  );
}

export default Main;
